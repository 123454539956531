
import { User } from "@/store/user/types";
import { Program } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component
export default class Home extends Vue {
  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  programs = [] as Program[];
  participants: Record<string, User> = {};

  loading = true;
  percentLoaded = 0;

  search = '';

  async mounted() {
    try {
      this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Presence', target: '/' }, { text: 'Programmas', target: 'presence/overview' }])
      const program = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id)))
      const users = await getDocs(query(collection(firestore, 'users')))

      users.forEach((doc) => {
        this.participants[doc.id] = {
          ...doc.data() as User,
          id: doc.id,
        }
      })

      this.programs = program.docs.map((doc) => {
        return {
          ...doc.data() as Program,
          id: doc.id,
        }
      })

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading = false;
    }

  }

  get filteredPrograms() {
    if(this.search === '') {
      return this.programs
    }
    return this.programs.filter((program) => {
      return program.title.toLowerCase().includes(this.search.toLowerCase())
    })
  }
}
